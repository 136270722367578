var headStyles = '';
var bodyClasses = '';

function pageTransition() {
  return Barba.BaseTransition.extend({
    start: function () {
      //wait for the new page content to load and the transitionOut to complete before modifying the DOM
      Promise
        .all([this.newContainerLoading, this.pageOut()])
        .then(this.finish.bind(this));
    },
    pageOut: function () {
      var deferred = Barba.Utils.deferred();

      if (!$('body').hasClass('menu-open')) {
        TweenMax.to('.pace, #preloader', 0.1, {
          autoAlpha: 1,
          onComplete: function () {
            if ('.slick-slider') {
              $('.slick-slider')
                .slick('unslick');
            }
            monkey.resume();
            deferred.resolve();
          }
        });
      } else {
        if ('.slick-slider') {
          $('.slick-slider')
            .slick('unslick');
        }
        deferred.resolve();
      }

      // console.log( 'transition current page out' );
      return deferred.promise;
    },
    finish: function () {
      //Remove the old content from the DOM
      this.done();

      var $ajaxStyles = $('#ajax-styles');

      if (headStyles) {
        if ($ajaxStyles.length) {
          $ajaxStyles.text(headStyles);
        } else {
          var $headStyles = $('<style id="ajax-styles">' + headStyles + '</style>');
          $('head')
            .append($headStyles);
        }
      } else if ($ajaxStyles.length) {
        $ajaxStyles.remove();
      }

      //Update active menu item.
      $('.menu-item').each(function () {
        var $menuItem = $(this);
        if ($menuItem.find('a')[0].pathname === window.location.pathname) {
          $menuItem.addClass('active');
        } else {
          $menuItem.removeClass('active');
        }
      });

      if (!$('body').hasClass('pace-running')) {
        Pace.restart();
      }

      $('body')
        .attr('class', bodyClasses);

      //scroll to the top of the page
      $(document)
        .scrollTop(0);

      //Load all page specific js from Sage variable
      UTIL.loadPage();

      eval($('.site-main').find('script').text()); // jshint ignore:line

      // console.log( 'Ajax transition successful' );
    },
  });
}

(function () {

  //Set ajax containers
  Barba.Pjax.Dom.wrapperId = 'ajax-container';
  Barba.Pjax.Dom.containerClass = 'site-main';

  //Update Google Analytics.
  Barba.Dispatcher.on('initStateChange', function () {
    if (typeof __gaTracker === 'function') {
      __gaTracker('send', 'pageview', location.pathname);
    }
  });

  //Get content outside from outside the wrapperId e.g. body classes
  Barba.Dispatcher.on('newPageReady', function (currentStatus, oldStatus, container, newPageRawHTML) {
    var response = newPageRawHTML.replace(/(<\/?)body( .+?)?>/gi, '$1notbody$2>', newPageRawHTML);
    headStyles = $(response).filter('#ajax-styles').text();
    bodyClasses = $(response).filter('notbody').attr('class');

    if ($('body').hasClass('menu-open')) {
      bodyClasses += ' menu-open';
    }

    // Check if the new page's body has the 'single-offer' class
    if ($(response).filter('notbody').hasClass('single-offer')) {
      // Use Barba to force a reload
      Barba.Pjax.goTo(window.location.href);
    }

    // Check if the new page's body has the 'contact-us' class
    if ($(response).filter('notbody').hasClass('contact-us')) {
      // Use Barba to force a reload
      Barba.Pjax.goTo(window.location.href);
    }
  });

  //Prevent Ajax on WP Admin links
  Barba.Pjax.originalPreventCheck = Barba.Pjax.preventCheck;
  Barba.Pjax.preventCheck = function (evt, element) {
    if (!Barba.Pjax.originalPreventCheck(evt, element)) {
      return false;
    }
    if ($(element)
      .hasClass('ab-item')) {
      return false;
    }
    return true;
  };

  //set custom page transition
  Barba.Pjax.getTransition = function () {
    return pageTransition();
  };

  Barba.Pjax.start();
})();
