function mobileOS() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  // Windows Phone must come first because its UA also contains "Android"

  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }
  if (/android/i.test(userAgent)) {
    return "Android";
  }
  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }
  return false;
}

function accordionToggle(e) {
  var $button = $(e.target),
    $description = $button.parents('dt')
      .next('dd'),
    $title = $button.parents('dt'),
    $height = $description.children()
      .outerHeight();

  if ($title.hasClass('open')) {
    TweenMax.to($description, $height / 1000, {
      height: 0,
      ease: Power1.easeOut,
      onComplete: function () {
        $description.removeAttr("style");
      }
    });
  } else {
    TweenMax.to($description, $height / 1000, {
      height: $height,
      ease: Power1.easeIn,
      onComplete: function () {
        $description.height('auto');
      }
    });
  }
  $button.parents('dt')
    .toggleClass('open');
}

function breakpoint() {
  const tablet_small = window.matchMedia('(min-width: 37.5rem)'), // jshint ignore:line
    tablet_medium = window.matchMedia('(min-width: 50rem)'),
    desktop = window.matchMedia('(min-width: 64.0625rem)');

  if (!desktop.matches && !tablet_medium.matches && !tablet_small.matches) {
    return 'mobile';
  } else if (!desktop.matches && !tablet_medium.matches) {
    return 'tablet-small';
  } else if (!desktop.matches) {
    return 'tablet-medium';
  } else {
    return 'desktop';
  }
}

function custom_datepicker() {
  function isChristmas(date) {
    var day = date.getDate();
    var month = date.getMonth() + 1;
    return (day === 25 && month === 12);
  }

  var $datepicker = $('.gfield:not(.group-booking-date) .datepicker');

  if (!$datepicker.length) {
    return;
  }
  var location = $('.locations select').val();
  if (location) {
    $datepicker.datepicker('destroy');
    $datepicker.datepicker({
      dateFormat: 'dd/mm/yy',
      minDate: 1,
      beforeShowDay: function (date) {
        day = moment(date, "DD/MM/YYYY");
        var formatDate = day.format('DD/MM/YY');
        var weekDay = day.format('dddd').toLowerCase();

        if (location === 'chelmsford' && formatDate === '18/04/18') {
          return [false, 'closed'];
        }

        if (isChristmas(date) || window.openingTimes[location][weekDay].status === 'closed') {
          return [false, 'closed'];
        } else {
          return [true, 'open'];
        }
      }
    });
    $datepicker.datepicker('refresh').prop('disabled', false);
  } else {
    $datepicker.prop('disabled', true);
  }
}

function file_upload() {
  var $inputs = $('input[type="file"]');
  if (!$inputs.length) {
    return;
  }
  $inputs.each(function () {
    var $input = $(this),
      $label = $('label[for="' + $input.attr('id') + '"]'),
      labelVal = $label.html();

    $label.append('<div class="upload-button"><span>Upload</span></div>');

    $input.on('change', function (e) {
      var fileName = '';

      if (e.target.value) {
        fileName = e.target.value.split('\\')
          .pop();
      }

      fileNameSplit = fileName.match(/.{1,15}/g)[0];

      if (fileName) {
        $label.html('');
        $label.append('<span class="file-name">' + fileName + '</span>')
          .addClass('has-file');
        $label.append('<span class="upload-button">Upload</span>');
      } else {
        $label.html(labelVal)
          .removeClass('has-file');
      }
    });

    // Firefox bug fix
    $input
      .on('focus', function () {
        $input.addClass('has-focus');
      })
      .on('blur', function () {
        $input.removeClass('has-focus');
      });
  });
}

//http://gregfranko.com/jquery.selectBoxIt.js/?Examples
function select_box() {
  var $select = $('select');
  if (!$select.length) {
    return;
  }
  $select.selectBoxIt({
    autoWidth: false,
    hideCurrent: true,
    showFirstOption: true,
    showEffect: 'slideDown',
    hideEffect: 'slideUp',
    showEffectSpeed: 'fast',
    hideEffectSpeed: 'fast'
  });
  // if ( $select.child('.gf_placeholder').length ) {
  //   console.log($select.child('.gf_placeholder'));
  // } //TODO: Disable placeholders so they're not selectable from the drop down.
}

function wrapWords() {
  var $elements = $('.word-wrap, .gform_title');
  if (!$elements.length) {
    return;
  }
  $elements.each(function (i, e) {
    $element = $(e);
    var words = $element.text()
      .split(' ');
    $element.empty();
    $.each(words, function (i, w) {
      if (i > 0 && i < words.length) {
        $element.append(' ');
      }
      $element.append($('<span>')
        .text(w));
    });
  });
}

function populateTimes() {
  var $time_list = $('.time select');
  if (!$time_list.length) {
    return;
  }

  var date = $('.date input')
    .val(),
    day = '',
    location = $('.locations select')
      .val();
  if (date) {
    date = moment($('.date input')
      .val(), "DD/MM/YYYY");
    day = date.format('dddd')
      .toLowerCase();
  }


  if (location && day) {
    var $placeHolder = $($time_list.find('.gf_placeholder'));
    var selectedTime = $time_list.val();
    // console.log(date);
    $time_list.empty();

    if (window.openingTimes[location][day].status === 'open') {
      var open = moment(window.openingTimes[location][day].open, 'HH:mm:ss');
      var close = moment(window.openingTimes[location][day].close, 'HH:mm:ss')
        .subtract(1, 'hour');
      var list = [];

      if (open.isAfter(close)) {
        close.add(1, 'day');
      }
      var validTime = false;

      while (open.isSameOrBefore(close)) {
        var format = 'h:mma',
          time = open.format(format);

        if (time === selectedTime) {
          validTime = true;
        }

        //NOTE: Bookings cannot be made on Fridays & Saturdays or Valentine's Day between 7.45pm and 9.00pm (apart from leatherhead).
        if (location !== 'leatherhead' && $.inArray(day, ['friday', 'saturday']) !== -1 && open.isBetween(moment('7:45pm', format), moment('9:00pm', format))) {
          open.add(30, 'minutes');
          continue;
        } else if (date.format('DD/MM') === '14/02' && open.isBetween(moment('7:45pm', format), moment('9:00pm', format))) {
          open.add(30, 'minutes');
          continue;
        }

        $time_list.append('<option value="' + time + '"' + (time === selectedTime ? ' selected="selected"' : '') + '>' + time + '</option>');
        open.add(30, 'minutes');
      }
      if (validTime) {
        $placeHolder.removeAttr('selected');
        $time_list.prepend($placeHolder);
      } else {
        $placeHolder.attr('selected', true);
        $time_list.prepend($placeHolder)
          .prop('selectedIndex', 0);
      }
      $time_list.prop('disabled', false);
    } else {
      $time_list.prepend($placeHolder);
      $time_list.prop('disabled', true)
        .prop('selectedIndex', 0);
    }
  } else {
    $time_list.prop('disabled', true)
      .prop('selectedIndex', 0);
  }
  $time_list.data('selectBox-selectBoxIt')
    .refresh();
}

function appendWaves() {
  $('.gform_body, .gform_confirmation_wrapper')
    .prepend("<div class='waves'></div>");
}

function monkeyPadding() {
  if ($('#monkey-svg').length) {
    var viewbox = $('#monkey-svg')[0].getAttribute('viewBox').split(/\s+|,/),
      viewBoxWidth = viewbox[2],
      viewBoxHeight = viewbox[3],
      paddingTop = viewbox[3] / viewbox[2] * 100;
    $('#monkey-padding').css('padding-top', paddingTop + '%');
  }
}

function loadOpenTableScripts() {
  var scriptDesktop = $('div.open-table.desktop script:first-of-type');
  var scriptMobile = $('div.open-table.mobile script:first-of-type');

  var newScript = document.createElement("script");
  newScript.src = scriptDesktop.attr('src');

  if (screen.width > 800) {
    var scriptDesktopDOM = document.querySelectorAll('.open-table.desktop');
    if (scriptDesktopDOM[0]) {
      $('div.open-table.desktop').html('');
      scriptDesktopDOM[0].appendChild(newScript);
    }
  }

  var newScript2 = document.createElement("script");
  newScript2.src = scriptMobile.attr('src');

  if (screen.width <= 800) {
    var scriptMobileDOM = document.querySelectorAll('.open-table.mobile');
    if (scriptMobileDOM[0]) {
      $('div.open-table.mobile').html('');
      scriptMobileDOM[0].appendChild(newScript2);
    }
  }

}

function eventToOpenMainBookingScript() {
  const bookingButton = document.querySelector('a[href="#booking"]');

  // Select the element you want to simulate the click on
  const targetElement = document.getElementById('sr-res-root-header');

  if (bookingButton && targetElement) {
    // Add a click event listener to the button
    bookingButton.addEventListener('click', function (event) {
      event.preventDefault(); // Prevent default link behavior if needed
      // Simulate the click on the target element
      targetElement.click();
    });
  }
}

function getIEVersion() {
  var sAgent = window.navigator.userAgent;
  var Idx = sAgent.indexOf("MSIE");

  // If IE, return version number.
  if (Idx > 0) {
    return parseInt(sAgent.substring(Idx + 5, sAgent.indexOf(".", Idx)));
  }

  // If IE 11 then look for Updated user agent string.
  else if (!!navigator.userAgent.match(/Trident\/7\./)) {
    return 11;
  }

  else {
    return 0; //It is not IE
  }
}


function scrollDown(button, section) {
  $(button).click(function (e) {
    e.preventDefault();
    $('html, body').animate({
      scrollTop: $(section).offset().top
    }, 900);
  });
}


function updateOffers(e, $list) {
  TweenLite.to('#offer-list', 0.5, {
    autoAlpha: 0
  });
  setTimeout(function () {
    $('#offer-list .container h2').text(e.value);
    var i = 1;
    $list.each(function () {

      if ($(this).hasClass(e.value)) {
        var url = window.location.host;
        var id = $(this).attr('id').split('-');
        id.shift();
        var offerSlug = id.join('-');
        $(this).children('a').attr('href', '/offers/' + offerSlug + '?location=' + e.value);
        $(this).children('a').attr('onclick', 'location.href ="' + '/offers/' + offerSlug + '?location=' + e.value + '"');
        $(this).css('display', '');
        $('body').addClass('offers-filtered');

        if ($(window).width() > 600) {
          if (i % 2 === 0) {
            $(this).css('margin-top', '60px');
          } else {
            $(this).css('margin-top', '0');
          }
          i++;
        }
      }
      else {
        $(this).css('display', 'none');
      }
    });
  }, 500);
  TweenLite.to('#offer-list', 0.5, {
    autoAlpha: 1,
    delay: 0.5
  });

  var location = e.value;
}


function tcModal() {
  var modal = document.getElementById('offer-tc-modal'),
    closeModal = document.getElementById('close-tc-modal'),
    $body = $(document.body);

  if (modal !== null) {
    $('#offer-tc-open').on('click', function (e) {
      e.preventDefault();
      TweenMax.to(modal, 0.3, { autoAlpha: 1, ease: Power1.easeInOut });
      $body.addClass('modal-open');
    });

    closeModal.onclick = function () {
      TweenMax.to(modal, 0.3, { autoAlpha: 0, ease: Power1.easeInOut });
      $body.removeClass('modal-open');
    };

    window.onclick = function (event) {
      if (event.target === modal) {
        TweenMax.to(modal, 0.3, { autoAlpha: 0, ease: Power1.easeInOut });
        $body.removeClass('modal-open');
      }
    };
  }
}
