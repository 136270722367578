// ScrollMagic init controller

var controller = new ScrollMagic.Controller();

// Waves parallax

function waves() {

  var $parallaxElements = $('body').find('.waves').toArray();
  if ( !$parallaxElements.length ) {
    return;
  }

  $parallaxElements.forEach(function(e, i) {
    var $element = $(e);
    var scene = new ScrollMagic.Scene({
      triggerElement: $element.parent()[0],
      offset: '-' + $(window).height() / 2,
      triggerHook: 0.5,
      duration: $(window).height() * 1.5 + $element.parent().outerHeight()
    })
    // .addIndicators() // add indicators (requires plugin)
    .setTween(TweenMax.fromTo(e, 1, {y: '15%'}, {y: '-15%'}))
    .addTo(controller);
  });
}

function monkeyDrum() {
  var tl = new TimelineMax({
    repeat: -1,
    yoyo: true
    // repeatDelay: 1
  }),
      leftHand = $('#monkey .left-hand'),
      rightHand = $('#monkey .right-hand'),
      speed = 0.35;

      tl.to(leftHand, speed, {
      rotation: '-10',
       x: '-20px',
       transformOrigin: 'right, 50'
       // repeat: 10
     })
     .fromTo(rightHand, speed, {
       rotation: '-10',
       x: '-10px',
       transformOrigin: 'left, 50%',
       // repeat: 9
     }, {
       rotation: '10',
       x: "+=10px"
     }, '-=' + speed);

  var scene = new ScrollMagic.Scene({
        triggerElement: '#monkey',
        triggerHook: 1
      })
      .setTween(tl)
      .addTo(controller);

}

function shadowParallax() {
  var shadow = $('.shadow');

  shadow.each(function() {
    var tween = TweenMax.fromTo($(this), 1, {y: 'auto', x: 'auto'}, {y: '10%', x: '-3%'});
    var scene = new ScrollMagic.Scene({
      triggerElement: this,
      triggerHook: 0.5,
      duration: $(window).height() + $(this).outerHeight()
    })
    // .addIndicators({name: 'Shadow Parallax', colorEnd: '#FFFFFF'}) // add indicators (requires plugin)
    .setTween(tween)
    .addTo(controller);
  });
}

function foodSliderDots() {
  var foodSliderDots = '#menu .slick-dots button',
      foodSlider = '#menu',
      tl = new TimelineMax();
      if ( !$(foodSlider).length ) {
        return;
      }

    tl.staggerFrom($(foodSliderDots), 0.3, {
      y: '-10px',
      autoAlpha: 0
    }, 0.15);

    var scene = new ScrollMagic.Scene({
      triggerElement: foodSlider,
      triggerHook: 'onEnter',
      offset: 100,
      reverse: false
    })
    .setTween(tl)
    .addTo(controller);

}

function contentReveal() {
  var images = $('.fade-in'),
      content= $('.fade-in-first'),
      foodSlider = '.slick-slide dl > div',
      slickActiveMeats = '.slick-slide dl > div';

  content.each(function(){
    var tween = TweenMax.to($(this), 1, {autoAlpha: 1});
    var scene = new ScrollMagic.Scene({
      triggerElement: this,
      triggerHook: 'onEnter',
      // offset: 100,
      reverse: false
    })
    // .addIndicators({name: 'Text faster load', colorEnd: 'red'})
    .setTween(tween)
    .addTo(controller);
  });

  images.each(function(){
    var tween = TweenMax.to($(this), 1, {autoAlpha: 1, delay: 0.05});
    var scene = new ScrollMagic.Scene({
      triggerElement: this,
      triggerHook: 'onEnter',
      offset: 100,
      reverse: false
    })
    // .addIndicators({name: 'Boxes load', colorEnd: 'red'})
    .setTween(tween)
    .addTo(controller);
  });
}

function staggerLocations() {
  var locations = $('#location-list > li');


  locations.each(function(){
    var tween = TweenMax.staggerTo($(this), 1, {autoAlpha: 1}, 0.2);
    var scene = new ScrollMagic.Scene({
      triggerElement: this,
      triggerHook: 'onEnter',
      offset: 200,
      reverse: false
    })
    .addIndicators({name: 'Locations stagger', colorEnd: '#EE857A'})
    .setTween(tween)
    .addTo(controller);
  });
}


// function simpleParallax(intensity, element) {
//         $(window).scroll(function() {
//             var scrollTop = $(window).scrollTop();
//             var imgPos = scrollTop / intensity + 'px';
//             element.css('transform', 'translate3d(0px -' + imgPos + ' 0px)');
//         });
//     }
//
// simpleParallax(11, cta);

// Nav animation

function  headerContentScroll() {
  var content = $('.header-content-scroll'),
      header = $('#page-header'),
      headerInner = $('#header-inner'),
      wavesHeader = $('.waves-header'),
      backgroundColour = $('#background-colour'),
      tl = new TimelineMax(),
      tlWaves = new TimelineMax();

      tlWaves.from(wavesHeader, 1, {
              autoAlpha: 0
            });

      tl.to(headerInner, 1, {
        autoAlpha: 0
      })
      .to(backgroundColour, 1, {
        autoAlpha: 1
      }, '-=1')
      .to(content, 1, {
        autoAlpha: 0,
        y: '-70vh'

      }, '-=1');

  var scene = new ScrollMagic.Scene({
      triggerElement: '#page-header',
      triggerHook: 'onLeave',
      duration: header.outerHeight() - 200
      })
      .setTween(tl)
      .addTo(controller);

     // console.log(header.outerHeight() - 200);

  var sceneWaves = new ScrollMagic.Scene({
      triggerElement: '#page-header',
      triggerHook: 'onLeave',
      duration: header.outerHeight() / 2.5
    })
      // .addIndicators({name: 'Header', colorEnd: '#EE857A', colorStart: '#EE857A'})
      .setTween(tlWaves)
      .addTo(controller);
}

function navAnimation() {

  var tlNav = new TimelineMax(),
      siteHeaderWrapper = $('#site-header-wrapper'),
      siteHeaderOuter = $('#site-header-outer'),
      logo = $('#site-header .site-logo'),
      socialLinks = ('#site-header .social-links'),
      menu = $('#site-header .menu'),
      hamburger = $('.hamburger'),
      timeWrapper = 0.7,
      timeContent = 0.9,
      ease = Power3.easeInOut;


  tlNav.to(siteHeaderWrapper, timeWrapper, {
    css: { 'transform' : 'scale(1.5)' },
    ease: ease
  })
  .to(siteHeaderOuter, timeWrapper, {
    bottom: 0,
    ease: ease
  }, '-=' + timeWrapper)
  .fromTo(menu, timeContent, {
    top: '-=20px',
    autoAlpha: 0
  }, {
    top: '50%',
    autoAlpha: 1,
  }, '-=0.3')
  .fromTo([logo, socialLinks, hamburger], timeContent, {
    y: '-20px',
    autoAlpha: 0
  }, {
    y: 'auto',
    autoAlpha: 1,
  }, '-=' + timeContent);

}

// Nav animation mobile

// function navMobile() {
//   var tl = new TimelineMax(),
//       hamburger = $('.hamburger'),
//       nav = $('#site-header nav'),
//       menuHeader = $('#menu-header')
//
//   if (!$('body').hasClass('menu-open')) {
//     console.log('menu NOT open')
//     tl.fromTo(menuHeader, 1, {
//       autoAlpha: 0
//     }, {
//       autoAlpha: 1
//     })
//   }
// }

// Header image animation

function headerImageAnimation() {
  // var $headerImage = $('#header-slider figure');
  // if ( !$headerImage.length ) {
  //   return;
  // }
  // TweenMax.to($headerImage, 40, {
  //   scale: 1.2
  // })
}

// Preloader

var tlMonkey = new TimelineMax({
  repeat: -1,
  yoyo: true
});

var monkey = {
  init: function() {

    var monkeyBody = $('#monkey-body'),
        leftHand = $('#left-hand'),
    		rightHand = $('#right-hand'),
    		leftLeg = $('#left-leg'),
    		rightLeg = $('#right-leg'),
    		tail = $('#monkey-tail'),
    		loading = $('#loading'),
    		runtime = 0.37;

    tlMonkey.to([monkeyBody, rightHand, leftHand, leftLeg, rightLeg], runtime, {
    	 y: '-40px'
    }, "-=" + runtime)
    .to(tail, runtime, {
    	y: '-40px',
    	x: '+20px',
    	rotation: '20',
    	transformOrigin: 'right, 50%'
    }, "-=" + runtime)
      .to(rightHand, 0.15, {
      rotation: '-10',
    	transformOrigin: 'left, 50%',
      x: '-10px',
    	repeat: 1,
    	yoyo: true,
    }, "-=" + runtime)
      .to(leftHand, 0.15, {
      rotation: '-10',
    	transformOrigin: 'right, 50%',
      x: '-10px',
    	repeat: 1,
    	yoyo: true,
    }, "-=" + runtime)
    .to(rightLeg, runtime, {
       y: '-20px'
    }, "-=" + runtime)
    .to(leftLeg, runtime, {
       y: '-10px'
    }, "-=" + runtime)
    .to(loading, runtime, {
      rotation: '-7',
      ease: Back.easeOut.config(1.7)
    }, "-=" + runtime);
  },
  resume: function() {
    tlMonkey.resume();
  },
  pause: function() {
    tlMonkey.pause();
  }
};

// Rodizio experience animation

var tlExperience = new TimelineMax();

var rodizioExperience = {

  init: function(){
    var cardRed = $('#card-red'),
        cardGreen = $('#card-green'),
        // cardRed = document.querySelector('#card-red'),
        // cardGreen = document.querySelector('#card-green'),
        time = 1.2,
        durationHand = 1,
        handGreen = $('#hand-green'),
        handRed = $('#hand-red'),
        backgrond = $('.the-rodizio-experience #page-header'),
        ease = Elastic.easeOut.config(1, 0.7);


    // cardRed.addEventListener('click', function(event) {
      cardRed.click(function(){

        if (!tlExperience.isActive()) {

          backgrond.css('background-color', '#DE685F');
          tlExperience.to(cardRed, time, {
          rotationY: 180
        }).to(cardGreen, durationHand, {
          rotationY: 360
          }, '-=' + time)
          .fromTo(handGreen, durationHand, {
            rotation: 0,
          }, {
            rotation: 180,
            ease: ease
          }, '-=' + time)
          .fromTo(handRed, durationHand, {
            rotation: -180,
          }, {
            rotation: 0,
            ease: ease
          }, '-=' + durationHand);
        }
      });

    cardGreen.click(function(){

      if (!tlExperience.isActive()) {
        backgrond.css('background-color', '#A5BC6D');
        tlExperience.to(cardGreen, time, {
        rotationY: 180
      }).to(cardRed, durationHand, {
        rotationY: 360
        }, '-=' + time)
        .fromTo(handRed, durationHand, {
          rotation: 0,
        }, {
          rotation: 180,
          ease: ease
        }, '-=' + time)
        .fromTo(handGreen, durationHand, {
          rotation: -180,
        }, {
          rotation: 0,
          ease: ease
        }, '-=' + durationHand);
      }
    });
  }
};
