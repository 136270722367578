function appHeaderOffset() {
  function setOffset() {
    if ( $.inArray( breakpoint(), [ 'mobile' ] ) > -1 ) {
      $( '#app-image' )
        .css( 'margin-top', $( '.container h1' )
          .outerHeight() );

    } else {
      $( '#app-image' )
        .removeAttr( 'style' );
    }
  }
  setOffset();
  $( window )
    .resize( function () {
      setOffset();
    } );
}

function menuDotsOffset( breakpoint ) {
  if ( $.inArray( breakpoint, [ 'mobile', 'tablet-small' ] ) > -1 ) {
    $( '#menu .slick-slide .food-figure-wrapper' )
      .css( 'margin-bottom', $( '#menu .slick-dots' )
        .outerHeight() );
    $( '#menu .slick-dots' )
      .css( 'top', $( '#menu .slick-slider .slick-active .food-figure-wrapper' )
        .outerHeight() );
  } else {
    $( '#menu .slick-slide .food-figure-wrapper, #menu .slick-dots' )
      .removeAttr( 'style' );
  }
}

function bookTableOffset() {
  var $bookTable = $( '#book-a-table' );
  var $siteHeader = $( '#site-header' );

  function setOffset() {
    var siteHeaderOffset = false;

    if ( $( 'body' )
      .hasClass( 'book-a-table' ) ) {
      siteHeaderOffset = true;
    }

    if ( $bookTable ) {
      $bookTable.find( '.gform_heading' )
        .css( {
          'margin-top': ( siteHeaderOffset ? ( $siteHeader.outerHeight() / 2 ) : 0 ),
          'padding-bottom': $bookTable.find( '.gform_page:not([style]) .gform_page_fields' )
            .height() + $bookTable.find( '.gform_page:not([style]) .gform_page_footer' )
            .outerHeight( true )
        } );

      $bookTable.find( '.gform_page_fields' )
        .css( {
          'margin-top': ( siteHeaderOffset ? $siteHeader.outerHeight() : 0 ),
          'padding-top': $bookTable.find( '.gform_heading' )
            .height()
        } );
    }
  }
  setOffset();
  $( window )
    .resize( function () {
      setOffset();
    } );
}

function siteHeaderOffset() {
  $( '#ajax-container' )
    .css( 'padding-top', $( '#site-header' )
      .outerHeight() );
  $( '#site-header nav' )
    .css( 'margin-top', $( '#site-header' )
      .outerHeight() );
}
